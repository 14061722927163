import React from "react";
import { Navigate, RouteObject } from "react-router-dom";
import { LoginPage } from "./submodules/login/LoginPage";
import { HomePage } from "./submodules/home/HomePage";
import { AdminPartnersPage } from "./submodules/partners/PartnersPage";
import { GeneralInfo } from "./submodules/general-info/components/GeneralInfo";
import { AdminEditals } from "./submodules/editals/AdminEditals";

const AdminRoute: RouteObject = {
  path: "admin",
  children: [
    {
      index: true,
      element: <Navigate replace to="./login" />,
      loader: async (params) => {
        console.log(params.request);
        return null;
      },
    },
    {
      path: "login",
      element: <LoginPage />,
    },
    {
      path: "home",
      element: <HomePage />,
      children: [
        {
          index: true,
          element: <Navigate replace to="./general-info" />,
        },
        {
          path: "general-info",
          element: <GeneralInfo />,
        },
        {
          path: "partners",
          element: <AdminPartnersPage />,
        },
        {
          path: "editals",
          element: <AdminEditals />,
        },
      ],
    },
  ],
};

export default AdminRoute;
