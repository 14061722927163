import styled from "styled-components";
import AppColors from "../AppColors";

interface IButtonTypeEditalsProps {
  $isActive?: boolean;
}

const SectionButton = styled.button<IButtonTypeEditalsProps>`
  font-family: "FlamaTrialBold";
  font-size: 1.5rem;
  border-radius: 10px;
  padding-inline: 24px;
  padding-block: 12px;
  border: none;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  @media only screen and (max-width: 500px) {
    font-size: 0.625rem;
    padding-inline: 16px;
    padding-block: 8px;
  }

  ${(props) => {
    if (props.$isActive) {
      return `
      color: ${AppColors.blue100};
      background-color: ${AppColors.yellow150};
      `;
    } else {
      return `
            color: ${AppColors.yellow150};
            cursor: pointer;
            background-color: transparent;
            border: 2px solid ${AppColors.yellow150};
            &:hover {
              color: ${AppColors.blue100};
              background-color: ${AppColors.yellow150};
              box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
              transition: 0.2s;
            }
            @media only screen and (max-width: 500px) {
              border: 1px solid ${AppColors.yellow150};
            }
        `;
    }
  }}
`;

export default SectionButton;
