const logoHorizontal = require("../../assets/images/logo-horizontal.png");
const flagBrazil = require("../../assets/images/flag-brazil.png");
const flagSpanish = require("../../assets/images/flag-spanish.png");
const flagUSA = require("../../assets/images/flag-usa.png");
const nlBlueImg = require("../../assets/images/nl-blue.jpeg");
const iconEmail = require("../../assets/images/icon-email.png");

export {
  logoHorizontal,
  nlBlueImg,
  iconEmail,
  flagBrazil,
  flagSpanish,
  flagUSA,
};
