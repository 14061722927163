import { NlTextField } from "../../../../../../../commom/shared_components/NlTextField";
import { useForm } from "react-hook-form";
import React from "react";
import { NlPrimaryButton } from "../../../../../../../commom/shared_components/NlPrimaryButton";
import { collection, doc, getFirestore, updateDoc } from "@firebase/firestore";
import { PartnersDTO } from "../../../../../dtos/PartnersDTO";
import { instanceToPlain } from "class-transformer";
import { NLTextLanguageField } from "../../../../../../../commom/shared_components/NlTextLanguageField";

interface EditPartnerProps {
  partner: PartnersDTO;
  onEditPartnerDone: () => void;
}

export default function EditPartner({
  partner,
  onEditPartnerDone,
}: EditPartnerProps) {
  const { handleSubmit, register, reset } = useForm<PartnersDTO>({
    defaultValues: partner,
  });
  const db = getFirestore();

  async function updatePartner(input: PartnersDTO) {
    await updateDoc(
      doc(collection(db, "partners"), partner.id),
      instanceToPlain(input)
    );
    reset();
    onEditPartnerDone();
  }

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className="flex flex-col mb-6 w-full px-12"
    >
      <strong className="mt-2 h-12 text-2xl">Atualizar Parceiro</strong>
      <form
        onSubmit={handleSubmit(updatePartner)}
        className="flex flex-col w-full justify-between"
      >
        <NLTextLanguageField
          placeholder="Nome da universidade"
          mapRegister={{
            es: register("name.es", { required: true }),
            en: register("name.en", { required: true }),
            pt: register("name.pt", { required: true }),
          }}
        />
        <div className="h-2" />
        <NLTextLanguageField
          placeholder="Nome do País"
          mapRegister={{
            es: register("country.es", { required: true }),
            en: register("country.en", { required: true }),
            pt: register("country.pt", { required: true }),
          }}
        />
        <div className="flex flex-row gap-6">
          <div className=" grow">
            <NlTextField
              id="urlUniversity"
              label="Url da universidade"
              register={register("urlSiteUniversity", { required: true })}
            />
          </div>
          <div className=" grow">
            <NlTextField
              id="urlDoc"
              label="Url do documento"
              register={register("urlDoc")}
            />
          </div>
        </div>
        <div className="flex flex-row gap-6">
          <div className=" grow">
            <NlTextField
              id="urlImageBanner"
              label="Url Imagem Banner"
              register={register("urlImageBanner", { required: true })}
            />
          </div>
          <div className=" grow">
            <NlTextField
              id="urlImageCard"
              label="Url Imagem Card"
              register={register("urlImageCard", { required: true })}
            />
          </div>
        </div>
        <NlPrimaryButton
          label="Atualizar parceiro"
          loading={false}
          onClick={() => {}}
        />
      </form>
    </div>
  );
}
