import LandPageHeader from "./submodules/land-page-header/LandPageHeader";
import LandPageAboutUs from "./submodules/land-page-about-us/LandPageAboutUs";
import LandPageContactUs from "./submodules/land-page-contact-us/LandPageContactUs";
import LandPagePartnersSection from "./submodules/land-page-partners-section/LandPagePartnersSection";

import { LandPageContext } from "./LandPageContext";
import { useLandPageData } from "./LandPageHook";
import { useEffect } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { LocalizedLanguage } from "./data/models/LocalizedLanguageModel";
import LandPageEditalsSection from "./submodules/land-page-editals-section/LandPageEditalsSection";

function preloadImage(src: string) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = function () {
      resolve(img);
    };
    img.onerror = img.onabort = function () {
      reject(src);
    };
    img.src = src;
  });
}

export default function LandPage() {
  const data = useLandPageData();
  useEffect(() => {
    data?.partners.forEach((partner) => {
      if (partner.urlImageBanner) preloadImage(partner.urlImageBanner);
    });
  });
  const { i18n } = useTranslation();
  i18n.on("languageChanged", (lng) => {
    LocalizedLanguage.changeLanguage(lng);
  });
  return (
    <LandPageContext.Provider value={{ data: data! }}>
      {data && (
        <div className="whitespace-pre-line">
          <LandPageHeader />
          <LandPageAboutUs />
          <LandPageEditalsSection />
          <LandPagePartnersSection />
          <LandPageContactUs />
        </div>
      )}
    </LandPageContext.Provider>
  );
}
