import styled from "styled-components";
import React from "react";
import AppColors from "../../../../../commom/AppColors";
import { EditalDTO } from "../../../dtos/EditalDTO";
import { LocalizedLanguage } from "../../../data/models/LocalizedLanguageModel";
import { useLandPageContext } from "../../../LandPageContext";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-block: 60px;
  width: 280px;
  height: 300px;
  background-color: white;
  border-radius: 10px;
  padding-inline: 24px;
  padding-block: 32px;
  cursor: pointer;
  &:hover {
    background-color: ${AppColors.yellow};
    transition: 0.2s;
  }
  @media only screen and (max-width: 500px) {
    padding: 8px;
    width: 100px;
    height: 90px;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  color: ${AppColors.blue100};
  width: 100%;
  gap: 10px;

  div:nth-child(1) {
    font-family: "FlamSemiBold";
    font-size: 1.25rem;
    flex: 8;
  }

  div:nth-child(2) {
    font-family: "FlamaBook";
    font-size: 1rem;
    flex: 3;
    text-align: end;
  }

  @media only screen and (max-width: 500px) {
    div:nth-child(1) {
      font-size: 0.5rem;
    }
    div:nth-child(2) {
      font-size: 0.5rem;
    }
  }
`;

const FooterInfo = styled.div`
  display: flex;
  flex-direction: column;
  color: ${AppColors.blue100};

  div:nth-child(1) {
    font-family: "FlamSemiBold";
    font-size: 1.5rem;
  }
  div:nth-child(2) {
    font-family: "FlamaBook";
    font-size: 1.25rem;
  }

  @media only screen and (max-width: 500px) {
    div:nth-child(1) {
      font-size: 0.65rem;
    }
    div:nth-child(2) {
      font-size: 0.5rem;
    }
  }
`;

interface EditalCardProps {
  edital: EditalDTO;
}
const EditalCard = ({
  edital: { title, model, duration, partnerId, urlEdital, otherUniversity },
}: EditalCardProps) => {
  const { data } = useLandPageContext();
  const partner = data.partners.find((partner) => partner.id === partnerId);

  return (
    <Content
      onClick={() => {
        window.open(urlEdital, "_blank");
      }}
    >
      <Header>
        <div>{model[LocalizedLanguage.currentLanguage()]}</div>
        <div>{duration[LocalizedLanguage.currentLanguage()]}</div>
      </Header>
      <FooterInfo>
        <div>{title[LocalizedLanguage.currentLanguage()]}</div>
        <div>
          {partner?.name[LocalizedLanguage.currentLanguage()] ??
            otherUniversity?.[LocalizedLanguage.currentLanguage()] ??
            ""}
        </div>
      </FooterInfo>
    </Content>
  );
};

export default EditalCard;
