import React from "react";
import styled from "styled-components";
import AppColors from "../../../../../commom/AppColors";
import { PartnersDTO } from "../../../dtos/PartnersDTO";
import { LocalizedLanguage } from "../../../data/models/LocalizedLanguageModel";
import { NxSVG } from "../../../../../commom/shared_components/NxSvg";
import { IconInternet } from "../../../../../commom/icons/IconInternet";
import { IconPDF } from "../../../../../commom/icons/IconPDF";

const Overlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: ${AppColors.yellow};
  font-family: "FlamSemiBold";
  font-size: 1.5rem;
  color: ${AppColors.blue150};

  z-index: 2;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 400px;
  width: 350px;
  background-color: ${AppColors.blue100};
  border-radius: 10px;
  overflow: hidden;
  position: relative;

  &:hover {
    ${Overlay} {
      visibility: visible;
      cursor: pointer;
      opacity: 0.8;
      transition:
        visibility 0s,
        opacity 0.2s linear;
    }
  }

  @media only screen and (max-width: 500px) {
    height: 150px;
    width: 130px;
  }
`;

const ImgPartiner = styled.img`
  flex-grow: 1;
  object-fit: cover;
`;

const Title = styled.div`
  font-family: "FlamSemiBold";
  font-size: 1.5rem;
  color: white;
  text-align: center;
  overflow: hidden;

  @media only screen and (max-width: 500px) {
    font-size: 0.5rem;
    max-height: 1.2rem;
    margin: 8px;
  }
`;

const PartnerCard = ({
  name,
  urlDoc,
  urlImageCard,
  urlSiteUniversity,
}: PartnersDTO) => (
  <Content>
    <ImgPartiner src={urlImageCard}></ImgPartiner>
    <Title className=" line-clamp-1 m-3 sm:line-clamp-2">
      {name[LocalizedLanguage.currentLanguage()]}
    </Title>
    <div className="absolute right-1 top-1 sm:right-2 sm:top-3 flex flex-row gap-2 items-center">
      {urlDoc && (
        <div
          className="bg-nl-blue-100 p-1 sm:p-2 cursor-pointer rounded-full"
          onClick={() => {
            if (urlDoc) {
              window.open(urlDoc, "_blank");
            }
          }}
        >
          <NxSVG className="fill-white h-4 w-4 sm:w-6 sm:h-6">
            <IconPDF />
          </NxSVG>
        </div>
      )}

      <div
        className="bg-nl-blue-100 p-1 sm:p-2 cursor-pointer rounded-full"
        onClick={() => {
          window.open(urlSiteUniversity, "_blank");
        }}
      >
        <NxSVG className="fill-white h-4 w-4 sm:w-6 sm:h-6">
          <IconInternet />
        </NxSVG>
      </div>
    </div>
  </Content>
);

export default PartnerCard;
