import React, { useEffect, useState } from "react";
// import AppColors from "../../../../../../commom/AppColors";
import { NlTextField } from "../../../../../../commom/shared_components/NlTextField";
import { NlPrimaryButton } from "../../../../../../commom/shared_components/NlPrimaryButton";
import { useForm } from "react-hook-form";
import { signInWithEmailAndPassword, getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";

const logoHorizontal = require("../../../../../../assets/images/logo-horizontal.png");

type LoginInput = {
  email: string;
  password: string;
};

export const LoginPage = () => {
  const [loading, setLoading] = useState(false);
  const { handleSubmit, register, trigger } = useForm<LoginInput>();
  const navigate = useNavigate();

  useEffect(() => {
    getAuth().onAuthStateChanged((user) => {
      if (user) {
        navigate("/admin/home");
      }
    });
  }, []);

  const login = async (inputs: LoginInput) => {
    setLoading(true);
    try {
      await signInWithEmailAndPassword(
        getAuth(),
        inputs.email.trim(),
        inputs.password.trim()
      );
      navigate("/admin/home");
    } catch (_) {
      alert(
        "Usuário ou senha inválidos, verifique seus dados e tente novamente"
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-nl-blue-100 w-screen h-screen flex flex-col content-center items-center justify-center">
      <img src={logoHorizontal} alt="" className="w-52" />
      <form
        onSubmit={handleSubmit(login)}
        className="bg-white shadow-sm flex w-80 flex-col gap-4 p-6 m-4 rounded-2xl"
      >
        <NlTextField
          register={register("email", { required: true })}
          label="E-mail"
          id="email"
        />
        <NlTextField
          register={register("password", { required: true })}
          label="Senha"
          id="password"
        />
        <NlPrimaryButton
          loading={loading}
          label="Entrar"
          onClick={() => trigger()}
        />
      </form>
    </div>
  );
};
