export default class AppColors {
  static blue = "#014457";
  static blue80 = "#0F6A84";
  static blue100 = "#014457";
  static blue150 = "#032A35";

  static yellow = "#F7D116";
  static yellow50 = "#FCE36B";
  static yellow100 = "#F7D116";
  static yellow150 = "#F7B816";

  static grey = "#656366";
  static grey50 = "#D0D0D0";
  static grey80 = "#A4A39F";
  static grey100 = "#656366";
  static grey150 = "#1E1E1E";

  static red = "#D52D12";
  static red100 = "#D52D12";
  static red150 = "#AD250F";
}
