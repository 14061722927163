import i18next from "i18next";
import en from "./locales/en.json";
import es from "./locales/es.json";
import pt from "./locales/pt.json";
import { initReactI18next } from "react-i18next";

i18next.use(initReactI18next).init({
  lng: navigator.language ?? "en",
  resources: {
    en: {
      translation: en,
    },
    es: {
      translation: es,
    },
    pt: {
      translation: pt,
    },
  },
});

export default i18next;
