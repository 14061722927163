import React, { useEffect } from "react";
import { useState } from "react";
import {
  deleteDoc,
  doc,
  collection,
  getFirestore,
  onSnapshot,
} from "@firebase/firestore";
import { ButtonDelete, ButtonEdit } from "./BtnEdit";
import EditPartner from "./EditPartner";
import { NlLoading } from "../../../../../../../commom/shared_components/NlLoading";
import { PartnersDTO } from "../../../../../dtos/PartnersDTO";
import { LocalizedLanguage } from "../../../../../data/models/LocalizedLanguageModel";

export default function RegisteredPartners() {
  const db = getFirestore();
  const collectionRef = collection(db, "partners");
  const [partners, setPartners] = useState<PartnersDTO[]>([]);
  const [editPartner, setEditPartner] = useState<PartnersDTO>();
  const [showEditForm, setShowEditForm] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    onSnapshot(collectionRef, (snapshot) => {
      const newPartnersList: PartnersDTO[] = snapshot.docs.map((doc) => {
        return {
          id: doc.id,
          ...doc.data(),
        } as PartnersDTO;
      });
      setPartners(newPartnersList);
      if (isLoading) {
        setIsLoading(false);
      }
    });
  }, []);

  const handleMessageClick = (partner: PartnersDTO) => {
    setEditPartner(partner);
    setShowEditForm(!showEditForm);
  };

  async function deleteDocument(idPartner: string) {
    const db = getFirestore();
    await deleteDoc(doc(db, "partners", idPartner));
  }

  return isLoading ? (
    <NlLoading />
  ) : (
    <div className="relative w-full px-12">
      {showEditForm && editPartner ? (
        <div
          onClick={(e) => {
            setShowEditForm(false);
            e.stopPropagation();
          }}
          className="fixed flex flex-col justify-center items-center bg-black/10 w-screen h-screen top-0 left-0 cursor-pointer"
        >
          <div className="flex items-start pt-8 bg-white border-2 w-[800px] h-[90%] shadow-2xl rounded overflow-y-auto">
            <EditPartner
              onEditPartnerDone={() => setShowEditForm(false)}
              partner={editPartner}
            />
          </div>
        </div>
      ) : (
        <div></div>
      )}
      <h2 className="mt-2 h-12 text-2xl">
        <strong>Parceiros Cadastrados</strong>
      </h2>
      <table className="w-full table-fixed ">
        <thead>
          <tr className="[&>th]:p-2 [&>th]:text-md bg-nl-blue-100  text-white text-start">
            <th className="text-start" colSpan={2}>
              Nome
            </th>
            <th className="text-start" colSpan={2}>
              País
            </th>
            <th className="text-start" colSpan={2}>
              Url da universidade
            </th>
            <th className="text-start" colSpan={2}>
              Url Img Banner
            </th>
            <th className="text-start" colSpan={2}>
              Url Img Card
            </th>{" "}
            <th className="text-start" colSpan={2}>
              Url Documento
            </th>
            <th className="text-start" colSpan={1}></th>
          </tr>
        </thead>
        <tbody>
          {partners?.map((partner) => {
            return (
              <tr
                className="[&>td]:p-2  text-xs font-medium [&>td]:border"
                key={partner.id}
              >
                <td colSpan={2}>
                  <div className="line-clamp-2">
                    {partner.name[LocalizedLanguage.currentLanguage()]}
                    {` (${LocalizedLanguage.currentLanguage()})`}
                  </div>
                </td>
                <td colSpan={2}>
                  <div className="line-clamp-2">
                    {partner.country[LocalizedLanguage.currentLanguage()]}
                    {` (${LocalizedLanguage.currentLanguage()})`}
                  </div>
                </td>
                <td colSpan={2}>
                  <a
                    className="line-clamp-2 hover:underline hover:text-blue-700"
                    title={partner.urlSiteUniversity}
                    href={partner.urlSiteUniversity}
                    target="blank"
                  >
                    {partner.urlSiteUniversity}
                  </a>
                </td>
                <td colSpan={2}>
                  <a
                    className="line-clamp-2 hover:underline hover:text-blue-700"
                    title={partner.urlImageBanner}
                    href={partner.urlImageBanner}
                    target="blank"
                  >
                    {partner.urlImageBanner}
                  </a>
                </td>
                <td colSpan={2}>
                  <a
                    className="line-clamp-2 hover:underline hover:text-blue-700"
                    title={partner.urlImageCard}
                    href={partner.urlImageCard}
                    target="blank"
                  >
                    {partner.urlImageCard}
                  </a>
                </td>
                <td colSpan={2}>
                  <a
                    className={`line-clamp-2 hover:underline ${partner.urlDoc ? "hover:text-blue-700" : ""}`}
                    title={partner.urlDoc}
                    href={partner.urlDoc}
                    target="blank"
                  >
                    {partner.urlDoc ? partner.urlDoc : "Não informado"}
                  </a>
                </td>
                <td colSpan={1} className="p-0">
                  <div className="flex flex-row gap-3 w-full justify-center">
                    <ButtonEdit onClick={() => handleMessageClick(partner)} />
                    <ButtonDelete onClick={() => deleteDocument(partner.id)} />
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <div className="h-16"></div>
    </div>
  );
}
