import {
  collection,
  getDocs,
  getFirestore,
  getDoc,
  doc,
} from "@firebase/firestore";
import { PartnersDTO } from "../dtos/PartnersDTO";
import { GeneralInfoDTO } from "../dtos/GeneralInfoDTO";
import { EditalDTO } from "../dtos/EditalDTO";

export class LandPageRepository {
  async fetchAllSections(): Promise<LandPageModel> {
    const db = getFirestore();
    const collectionRef = collection(db, "partners");
    const editalsRef = collection(db, "editals");
    const docResult = await getDocs(collectionRef);
    const generalInfo = (
      await getDoc(doc(collection(db, "general-info"), "main"))
    ).data() as GeneralInfoDTO;
    const editalDocsResult = await getDocs(editalsRef);
    const editals = editalDocsResult.docs.map((doc) => {
      return {
        id: doc.id,
        ...doc.data(),
      } as EditalDTO;
    });
    const partners = docResult.docs.map((doc) => {
      return {
        id: doc.id,
        ...doc.data(),
      } as PartnersDTO;
    });
    return {
      partners,
      generalInfo,
      editals: editals ?? [],
    };
  }
}

export interface LandPageModel {
  partners: PartnersDTO[];
  generalInfo: GeneralInfoDTO;
  editals: EditalDTO[];
}
