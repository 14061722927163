import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  flagBrazil,
  flagSpanish,
  flagUSA,
} from "../../../../../commom/images/images";
import { LocalizedLanguage } from "../../../data/models/LocalizedLanguageModel";

export const MenuLanguageOption = () => {
  const { i18n } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const [value, setValue] = useState(LocalizedLanguage.currentLanguage);
  const [isOpen, setIsOpen] = useState(false);

  const setLanguage = (language: string) => {
    i18n.changeLanguage(language);
    setValue(language);
    setIsOpen(false);
  };

  const getFlagImage = () => {
    const language = value;
    if (language.includes("pt")) return flagBrazil;
    if (language.includes("es")) return flagSpanish;
    return flagUSA;
  };

  useEffect(() => {
    const checkIfClickedOutside = (e: Event) => {
      if (isOpen && ref.current && !ref.current.contains(e.target as Node)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isOpen]);
  console.log(isOpen);
  return (
    <div ref={ref} className="relative  flex items-center   p-1">
      <div
        className="w-8 h-8 cursor-pointer
        after:w-[8px] after:h-[8px] after:border-white after:border-b-2 after:border-r-2 after:transform after:rotate-45 after:absolute  after:-right-0 sm:after:-right-2.5 after:top-[35%]
        "
        onClick={() => {
          setIsOpen(true);
        }}
      >
        <img src={getFlagImage()} alt="" />
      </div>
      <div
        className={"absolute top-full bg-nl-blue-150 p-2 w-full rounded flex flex-col items-center ".concat(
          isOpen ? "visible" : "invisible"
        )}
      >
        <div
          className="cursor-pointer w-full flex flex-row justify-center"
          onClick={() => setLanguage("pt")}
        >
          <img className="w-8" src={flagBrazil} alt="" />
        </div>
        <div
          className="cursor-pointer w-full flex flex-row justify-center"
          onClick={() => setLanguage("es")}
        >
          <img className="w-8" src={flagSpanish} alt="" />
        </div>
        <div
          className="cursor-pointer w-full flex flex-row justify-center"
          onClick={() => setLanguage("en")}
        >
          <img className="w-8" src={flagUSA} alt="" />
        </div>
      </div>
    </div>
  );
};
