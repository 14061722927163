import styled, { keyframes } from "styled-components";
import MenuBar from "./components/MenuBar";
import AppColors from "../../../../commom/AppColors";
import { useEffect, useState } from "react";
import React from "react";
import { useLandPageContext } from "../../LandPageContext";
import { LocalizedLanguage } from "../../data/models/LocalizedLanguageModel";
import { nlBlueImg } from "../../../../commom/images/images";

const HeaderStyle = styled.div`
  display: flex;
  background-color: ${AppColors.blue};
  width: 100vw;
  overflow: hidden;
  position: relative;
`;

const fadeIn = keyframes`
   0% { opacity: 0.5; }
  100% { opacity: 1; }
`;

const BakckGroundImage = styled.img`
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  animation: ${fadeIn} 1s linear;
`;

const BluedOverlay = styled.div`
  background-color: ${AppColors.blue};
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  opacity: 0.5;
`;

const LandPageHeader = () => {
  const { data } = useLandPageContext();
  const [currentIndexCarrousel, setcurrentIndexCarrousel] = useState(0);
  const carrouselNodes = [
    nlBlueImg,
    ...(data?.generalInfo.listCustomBanners ?? []),
    ...(data?.partners ?? []),
  ];

  function currentImage(): string | undefined {
    const currentNode = carrouselNodes[currentIndexCarrousel];
    if (typeof currentNode === "string") {
      return currentNode;
    }
    return currentNode.urlImageBanner;
  }

  function currentPartnerName(): string | undefined {
    const currentNode = carrouselNodes![currentIndexCarrousel];
    if (typeof currentNode !== "string") {
      return currentNode.name[LocalizedLanguage.currentLanguage()];
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setcurrentIndexCarrousel((currentIndexCarrousel) => {
        return (currentIndexCarrousel + 1) % (carrouselNodes?.length ?? 0);
      });
    }, 8000);
    return () => clearInterval(interval);
  }, []);

  return (
    <HeaderStyle>
      <MenuBar currentPartnerName={currentPartnerName()} />
      <BakckGroundImage key={currentIndexCarrousel} src={currentImage()} />
      <BluedOverlay />
    </HeaderStyle>
  );
};

export default LandPageHeader;
