import React from "react";
import RegisterPartners from "./components/RegisterPartners";
import RegisteredPartners from "./components/RegisteredPartners";

export const AdminPartnersPage = () => {
  return(
    <div className="h-screen overflow-x-hidden overflow-auto">
      <RegisterPartners />
      <RegisteredPartners/>
    </div>
  ) 
};
