import styled from "styled-components";
import AppColors from "../../../../commom/AppColors";
import SectionButton from "../../../../commom/shared_components/SectionButton";
import PartnerCard from "./components/PartnerCard";
import { useTranslation } from "react-i18next";
import { useLandPageContext } from "../../LandPageContext";
import { useState } from "react";
import React from "react";
import { LocalizedLanguage } from "../../data/models/LocalizedLanguageModel";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding-block: 60px;

  background-color: white;
  @media only screen and (min-width: 1200px) {
    padding-inline: calc((100vw - 1200px) / 2);
  }
  @media only screen and (max-width: 500px) {
    padding: 24px;
  }
`;

const Title = styled.div`
  font-size: 2.5rem;
  font-family: "FlamSemiBold";
  color: ${AppColors.blue100};

  @media only screen and (max-width: 500px) {
    font-size: 1.25rem;
  }
`;

const Description = styled.div`
  margin-top: 24px;
  font-size: 1.5rem;
  font-family: "FlamaBook";
  color: black;
  text-align: justify;

  @media only screen and (max-width: 500px) {
    font-size: 0.625rem;
    margin-top: 12px;
  }
`;

const RowHeaderButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  margin-block: 42px;
  @media only screen and (max-width: 500px) {
    margin-block: 24px;
  }
`;

const RowPartnersCards = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  flex-wrap: wrap;
`;
function groupBy<T, K extends keyof any>(
  list: T[],
  getKey: (item: T) => K
): Record<K, T[]> {
  return list.reduce(
    (accumulator, item) => {
      const key = getKey(item);
      if (!accumulator[key]) {
        accumulator[key] = [];
      }
      accumulator[key].push(item);
      return accumulator;
    },
    {} as Record<K, T[]>
  );
}

const LandPagePartnersSection = () => {
  const { t } = useTranslation();
  const { data } = useLandPageContext();
  const groupedParteners = groupBy(
    data?.partners ?? [],
    (partner) => partner.country[LocalizedLanguage.currentLanguage()]
  );

  const [currentCountry, setCurrentCountry] = useState(
    Object.keys(groupedParteners)[0]
  );

  return (
    <Content id="partners">
      <Title>{t("agreements")}</Title>
      <Description
        dangerouslySetInnerHTML={{
          __html:
            data?.generalInfo.agreements[LocalizedLanguage.currentLanguage()],
        }}
      ></Description>
      <RowHeaderButtons>
        {Object.keys(groupedParteners).map((country, index) => (
          <SectionButton
            key={index}
            $isActive={currentCountry === country}
            onClick={() => setCurrentCountry(country)}
          >
            {country}
          </SectionButton>
        ))}
      </RowHeaderButtons>

      <RowPartnersCards>
        {groupedParteners[currentCountry]?.map((partner, index) => (
          <PartnerCard key={index} {...partner} />
        ))}
      </RowPartnersCards>
    </Content>
  );
};
export default LandPagePartnersSection;
