import React from "react";

export const IconGeneralInfo = () => {
 return <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.3341 0H2.6674C1.20073 0 0.000732422 1.2 0.000732422 2.66667V21.3333C0.000732422 22.8 1.20073 24 2.6674 24H21.3341C22.8007 24 24.0007 22.8 24.0007 21.3333V2.66667C24.0007 1.2 22.8007 0 21.3341 0ZM14.6674 18.6667H5.33407V16H14.6674V18.6667ZM18.6674 13.3333H5.33407V10.6667H18.6674V13.3333ZM18.6674 8H5.33407V5.33333H18.6674V8Z"
      fill="white"
    />
  </svg>;
};
