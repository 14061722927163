import React, { forwardRef, useState } from "react";
import ArrowDropdown from "../../modules/land-page/submodules/admin/submodules/general-info/components/ArrowDropdown";
import { UseFormRegisterReturn } from "react-hook-form";

interface NlTextLanguageFieldProps {
  placeholder?: string;
  mapRegister: Record<string, UseFormRegisterReturn>;
}

export const NLTextLanguageField = ({
  placeholder,
  mapRegister,
}: NlTextLanguageFieldProps) => {
  const [showOptions, setShowOptions] = useState(false);

  return (
    <div className="w-full flex flex-col">
      <div
        onClick={() => setShowOptions(!showOptions)}
        className="w-full flex justify-between p-2 cursor-pointer font-medium border border-nl-blue-100 text-nl-blue-150 rounded-md"
      >
        <h2>{placeholder ?? "Versões do texto"}</h2>
        <ArrowDropdown />
      </div>
      <div
        className={`w-full bg-nl-yellow-50/10 p-2 font-medium text-nl-blue-150  ${showOptions ? "" : "hidden"}`}
      >
        <NlMultlineTextField
          id="pt"
          label="Português"
          register={mapRegister["pt"]}
        />
        <NlMultlineTextField
          id="en"
          label="Inglês"
          register={mapRegister["en"]}
        />
        <NlMultlineTextField
          id="es"
          label="Espanhol"
          register={mapRegister["es"]}
        />
      </div>
    </div>
  );
};
interface NlMultilineTextFieldProps {
  id: string;
  label: string;
  register?: any;
}
const NlMultlineTextField = forwardRef(
  ({ label, id, register }: NlMultilineTextFieldProps, forwardedRef) => {
    return (
      <div>
        <label
          htmlFor={id}
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          {label}
        </label>
        <div className="relative mt-2 rounded-md shadow-sm">
          <textarea
            ref={forwardedRef}
            id={id}
            name={id}
            {...register}
            type="text"
            className="block w-full
            rounded-md border-0 py-1.5 p-4
          text-gray-900
          ring-1 ring-inset 
          ring-nl-blue-150
          focus:outline-none
          focus:ring-2
         focus:ring-nl-blue-150
           sm:text-sm sm:leading-6 transition resize"
          />
        </div>
      </div>
    );
  }
);
