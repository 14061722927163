import { RouterProvider } from "react-router-dom";
import "./App.css";
import AppRoutes from "./AppRoutes";
import React from "react";

function App() {
  return <RouterProvider router={AppRoutes} />;
}

export default App;
