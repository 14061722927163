import React, { forwardRef, useEffect, useImperativeHandle } from "react";
import { Controller, useForm } from "react-hook-form";
import { EditalDTO } from "../../../../../dtos/EditalDTO";
import { NLTextLanguageField } from "../../../../../../../commom/shared_components/NlTextLanguageField";
import Select from "react-select";
import { PartnersDTO } from "../../../../../dtos/PartnersDTO";
import { LocalizedLanguage } from "../../../../../data/models/LocalizedLanguageModel";
import { NlPrimaryButton } from "../../../../../../../commom/shared_components/NlPrimaryButton";
import { NlTextField } from "../../../../../../../commom/shared_components/NlTextField";

interface AdminEditalFormProps {
  partners: PartnersDTO[];
  edital?: EditalDTO;
  submitLabel?: string;
  onSubmit: (data: EditalDTO) => void;
}
export interface AdminEditalFormRef {
  reset: () => void;
}

export const AdminEditalForm = forwardRef<
  AdminEditalFormRef,
  AdminEditalFormProps
>(({ partners, edital, onSubmit, submitLabel }, ref) => {
  const { register, handleSubmit, control, reset, setValue } =
    useForm<EditalDTO>({
      defaultValues: edital,
    });

  useEffect(() => {
    reset();
  }, [edital]);

  useImperativeHandle(
    ref,
    () => {
      return {
        reset() {
          reset();
        },
      };
    },
    []
  );

  const options = partners.map((partner) => {
    return {
      value: partner.id,
      label: partner.name[LocalizedLanguage.currentLanguage()],
    };
  });

  return (
    <div className="flex flex-col w-full">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="w-full">
          <Select
            key={new Date().getMilliseconds().toString()}
            placeholder="Selecione um parceiro"
            onChange={(selected) => {
              if (!selected) return;
              setValue("partnerId", selected?.value);
            }}
            styles={{
              control: (baseStyles) => ({
                ...baseStyles,
                borderColor: "#032A35",
                color: "#032A35",
              }),
            }}
            options={options}
          />
        </div>
        <div className="w-full mt-4">
          <NLTextLanguageField
            placeholder="Outra universidade parceira"
            mapRegister={{
              en: register("otherUniversity.en"),
              es: register("otherUniversity.es"),
              pt: register("otherUniversity.pt"),
            }}
          />
        </div>
        <div className="h-4"></div>

        <div className="w-full">
          <NlTextField
            id="urlEdital"
            label="Url do edital"
            register={register("urlEdital", {
              required: true,
            })}
          />
        </div>
        <div className="h-6"></div>
        <div className="flex flex-row gap-4">
          <div className="w-1/2">
            <NLTextLanguageField
              placeholder="Título"
              mapRegister={{
                en: register("title.en", {
                  required: true,
                }),
                es: register("title.es", {
                  required: true,
                }),
                pt: register("title.pt", {
                  required: true,
                }),
              }}
            />
          </div>
          <div className="w-1/2">
            <NLTextLanguageField
              placeholder="Modelo"
              mapRegister={{
                en: register("model.en", {
                  required: true,
                }),
                es: register("model.es", {
                  required: true,
                }),
                pt: register("model.pt", {
                  required: true,
                }),
              }}
            />
          </div>
        </div>
        <div className="h-6"></div>
        <div className="flex flex-row  gap-4 items-center">
          <div className="w-1/2">
            <NLTextLanguageField
              placeholder="Duração"
              mapRegister={{
                en: register("duration.en", {
                  required: true,
                }),
                es: register("duration.es", {
                  required: true,
                }),
                pt: register("duration.pt", {
                  required: true,
                }),
              }}
            />
          </div>
          <div className="w-1/2">
            <Controller
              defaultValue={false}
              name="isActive"
              control={control}
              render={({ field: { value } }) => (
                <div className="inline-flex items-center">
                  <label
                    className="relative flex items-center p-3 rounded-full cursor-pointer"
                    htmlFor="checkbox"
                  >
                    <input
                      key={new Date().toISOString()}
                      type="checkbox"
                      {...register("isActive")}
                      className="before:content[''] peer relative h-7 w-7 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:bg-gray-900 checked:before:bg-gray-900 hover:before:opacity-10"
                      id="checkbox"
                      checked={!!value}
                    />
                    <span className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4 w-4"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        stroke="currentColor"
                        strokeWidth="1"
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </label>

                  <label htmlFor="checkbox">Está ativo?</label>
                </div>
              )}
            />
          </div>
        </div>
        <div className="h-6"></div>
        <NlPrimaryButton
          className="mt-0 w-full"
          label={submitLabel ?? "Adicionar novo edital"}
          onClick={() => {}}
        />
      </form>
    </div>
  );
});
