import React from "react"

export default function ArrowDropdown(){
    return(
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_143_64)">
                <path d="M5.83325 8.33333L9.99992 12.5L14.1666 8.33333H5.83325Z" fill="black"/>
            </g>
            <defs>
                <clipPath id="clip0_143_64">
                    <rect width="20" height="20" fill="white"/>
                </clipPath>
            </defs>
        </svg>

    )
}