import styled from "styled-components";
import AppColors from "../../../../../commom/AppColors";

import { useLandPageContext } from "../../../LandPageContext";
import React from "react";
import { useTranslation } from "react-i18next";
import { LocalizedLanguage } from "../../../data/models/LocalizedLanguageModel";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  width: 100%;
  margin-block: 60px;
  @media only screen and (max-width: 500px) {
    font-size: 1rem;
    margin-block: 24px;
  }
  overflow-x: hidden;
`;

const Title = styled.div`
  font-size: 3rem;
  font-family: "FlamSemiBold";
  color: ${AppColors.blue100};
  @media only screen and (max-width: 500px) {
    font-size: 1rem;
    margin-inline: 24px;
  }
`;

const SubTitle = styled.div`
  margin-top: 24px;
  font-size: 1.5rem;
  font-family: "FlamaBook";
  color: black;
  text-align: justify;

  @media only screen and (max-width: 500px) {
    font-size: 0.625rem;
    margin-top: 12px;
    margin-inline: 24px;
  }
`;

const SectionOneAboutUs = () => {
  const { t } = useTranslation();
  const { data } = useLandPageContext();

  return (
    <Content>
      <Title>{t("aboutUs")}</Title>
      <SubTitle
        dangerouslySetInnerHTML={{
          __html:
            data.generalInfo.aboutUs1[LocalizedLanguage.currentLanguage()],
        }}
      ></SubTitle>
    </Content>
  );
};

export default SectionOneAboutUs;
