import React, { useState } from "react";
interface NLPrimaryButtonProps {
  label: string;
  onClick: () => Promise<unknown> | unknown;
  className?: string;
  loading?: boolean;
}
export const NlPrimaryButton = ({
  label,
  loading,
  onClick,
  className,
}: NLPrimaryButtonProps) => {
  const [_loading, setLoading] = useState(false);
  const onClickWithLoading = async () => {
    setLoading(true);
    await onClick();
    setLoading(false);
  };
  return (
    <button
      className={
        "bg-nl-blue-150 min-h-10 hover:bg-nl-blue-150/90 text-white mt-8 font-normal px-4 rounded transition " +
        (className ?? "")
      }
      onClick={onClickWithLoading}
    >
      {_loading || loading ? (
        <svg
          className="animate-spin m-auto h-5 w-5 text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      ) : (
        label
      )}
    </button>
  );
};
