import React from "react";
import styled from "styled-components";
import AppColors from "../../../../commom/AppColors";

import { useTranslation } from "react-i18next";
import { useLandPageContext } from "../../LandPageContext";
import { LocalizedLanguage } from "../../data/models/LocalizedLanguageModel";
import { iconEmail, logoHorizontal } from "../../../../commom/images/images";

const Content = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${AppColors.blue150};
  padding-block: 100px;
  justify-content: space-between;
  align-items: start;

  @media only screen and (min-width: 1200px) {
    padding-inline: calc((100vw - 1200px) / 2);
  }
  padding-inline: 24px;
`;

const ContentInfo = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
`;

const Title = styled.div`
  font-family: "FlamaTrialBold";
  font-size: 2rem;

  @media only screen and (max-width: 500px) {
    font-size: 1rem;
  }
`;

const Description = styled.div`
  margin-top: 12px;
  font-family: "FlamaBook";
  font-size: 1rem;
  text-align: justify;
  padding-right: 24px;

  @media only screen and (max-width: 500px) {
    margin-top: 8px;
    font-size: 0.5rem;
  }
`;

const Caption = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 32px;
  gap: 12px;

  img {
    width: 30px;
  }
  div {
    font-family: "FlamaBook";
    font-size: 1rem;
  }

  @media only screen and (max-width: 500px) {
    gap: 8px;
    margin-top: 16px;

    div {
      font-size: 0.5rem;
    }
    img {
      width: 16px;
    }
  }
`;
const LogoNiltonLins = styled.img.attrs({
  src: logoHorizontal,
})`
  height: 100%;
  max-height: 180px;

  @media only screen and (max-width: 500px) {
    max-height: 40px;
  }
`;

const LandPageContactUs = () => {
  const { t } = useTranslation();
  const { data } = useLandPageContext();

  return (
    <Content id="contact-us">
      <ContentInfo>
        <Title>{t("contactUs")}</Title>
        <Description
          dangerouslySetInnerHTML={{
            __html:
              data.generalInfo.contactUs[LocalizedLanguage.currentLanguage()],
          }}
        ></Description>
        <Caption>
          <img src={iconEmail} alt="Icone de email" />
          <div>{data.generalInfo.supportEmail}</div>
        </Caption>
      </ContentInfo>
      <LogoNiltonLins />
    </Content>
  );
};

export default LandPageContactUs;
