import { useAuthGuard } from "../../hooks/UseAuthGuard";
import React, { useEffect } from "react";
import { HomeNavBar } from "./components/HomeNavBar";
import { Outlet } from "react-router-dom";

export const HomePage = () => {
  const { resolveAuth } = useAuthGuard();
  useEffect(() => {
    resolveAuth();
  }, []);
  return (
    <div className="flex flex-row w-screen h-screen">
      <HomeNavBar />
      <div className="w-full h-full overflow-y-auto">
        <Outlet />
      </div>
    </div>
  );
};
