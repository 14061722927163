import styled from "styled-components";
import AppColors from "../../../../../commom/AppColors";
import React from "react";
import { useTranslation } from "react-i18next";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  align-items: center;
  max-width: 300px;
  gap: 8px;
  overflow: hidden;
  @media only screen and (max-width: 500px) {
    max-width: 90px;
  }
`;
interface ImagePointProps {
  $img?: string;
}

const ImagePoint = styled.div<ImagePointProps>`
  width: 180px;
  height: 180px;
  position: relative;
  background-color: #d9d9d9;
  border-radius: 100%;
  background-image: url(${(props) => props.$img});
  background-size: cover;
  @media only screen and (max-width: 500px) {
    max-width: 90px;
    width: 46px;
    height: 46px;
  }
  &:hover {
    :nth-child(1) {
      visibility: visible;
      opacity: 1;
    }
  }
`;

const Title = styled.div`
  color: ${AppColors.blue150};
  font-family: "FlamSemiBold";
  font-size: 1.5rem;
  @media only screen and (max-width: 500px) {
    font-size: 0.625rem;
  }
`;
const Description = styled.div`
  color: ${AppColors.blue80};
  font-family: "FlamaBook";
  font-size: 1.25rem;
  @media only screen and (max-width: 500px) {
    font-size: 0.5rem;
  }
`;

interface MacroPointProps {
  img: string;
  title: string;
  description: string;
  href?: string;
}

const OverlayMacroPoint = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 100%;
  color: white;

  font-family: "FlamSemiBold";
  font-size: 1.5rem;
  visibility: hidden;
  opacity: 0;
  transition:
    visibility 1s,
    opacity 0.5s linear;

  @media only screen and (max-width: 500px) {
    font-size: 0.625rem;
  }
`;
const MacroPoint = ({ title, description, img, href }: MacroPointProps) => {
  const { t } = useTranslation();

  return (
    <Content>
      <ImagePoint $img={img}>
        {href && (
          <OverlayMacroPoint onClick={() => window.open(href)}>
            {t("seeMore")}
          </OverlayMacroPoint>
        )}
      </ImagePoint>
      <Title>{title}</Title>
      <Description>{description}</Description>
    </Content>
  );
};

export default MacroPoint;
