import styled from "styled-components";
import AppColors from "../../../../commom/AppColors";
import React, { useState } from "react";
import EditalCard from "./components/EditalCard";
import SectionButton from "../../../../commom/shared_components/SectionButton";
import { useTranslation } from "react-i18next";
import { useLandPageContext } from "../../LandPageContext";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding-block: 60px;

  background-color: ${AppColors.blue100};
  @media only screen and (min-width: 1200px) {
    padding-left: calc((100vw - 1200px) / 2);
  }

  @media only screen and (max-width: 500px) {
    padding: 24px;
  }
`;

const Title = styled.div`
  font-family: "FlamaTrialBold";
  font-weight: bold;
  font-size: 3rem;
  color: white;

  @media only screen and (max-width: 500px) {
    font-size: 1.25rem;
  }
`;

const RowButtons = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  gap: 12px;
  margin-bottom: 64px;
  @media only screen and (max-width: 500px) {
    margin-top: 8px;
    margin-bottom: 16px;
    gap: 12px;
  }
`;

const RowEditalsCard = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;

  @media only screen and (max-width: 500px) {
    gap: 8px;
  }
`;

const LandPageEditalsSection = () => {
  const [isActive, setIsActive] = useState<boolean>(true);
  const { t } = useTranslation();
  const { data } = useLandPageContext();

  const editals = data.editals.filter((edital) => edital.isActive === isActive);

  const countActive = data.editals.filter((edital) => edital.isActive).length;
  const countInactive = data.editals.filter(
    (edital) => !edital.isActive
  ).length;

  if (data.editals.length === 0) {
    return <></>;
  }

  return (
    <Content id="editals">
      <Title>{t("inscriptions")}</Title>
      <RowButtons>
        {countActive > 0 && (
          <SectionButton $isActive={isActive} onClick={() => setIsActive(true)}>
            {t("opened")}
          </SectionButton>
        )}
        {countInactive > 0 && (
          <SectionButton
            $isActive={!isActive}
            onClick={() => setIsActive(false)}
          >
            {t("closed")}
          </SectionButton>
        )}
      </RowButtons>
      <RowEditalsCard>
        {editals.map((edital, index) => (
          <EditalCard key={index} edital={edital} />
        ))}
      </RowEditalsCard>
    </Content>
  );
};

export default LandPageEditalsSection;
