// import i18next from "i18next";

export class LocalizedLanguage {
  pt: string;
  en: string;
  es: string;

  constructor({ pt, en, es }: { pt: string; en: string; es: string }) {
    this.pt = pt;
    this.en = en;
    this.es = es;
  }

  static _currentLanguage = "pt";

  public static changeLanguage(language: string) {
    LocalizedLanguage._currentLanguage = language;
  }

  static currentLanguage() {
    const language = LocalizedLanguage._currentLanguage;
    if (language.includes("pt")) return "pt";
    if (language.includes("es")) return "es";
    if (language.includes("en")) return "en";
    return "en";
  }

  get value() {
    const language = LocalizedLanguage._currentLanguage;
    if (language.includes("pt")) return this.pt;
    if (language.includes("es")) return this.es;
    if (language.includes("en")) return this.en;
    return this.en;
  }
}
