import styled from "styled-components";
import AppColors from "../../../../../commom/AppColors";
import { useLandPageContext } from "../../../LandPageContext";
import React from "react";
import { LocalizedLanguage } from "../../../data/models/LocalizedLanguageModel";

const Content = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${AppColors.blue100};
  justify-content: space-between;
  width: 100%;

  div {
    margin-block: 60px;
    margin-right: 60px;
    font-family: "FlamaBook";
    font-size: 1.5rem;
    align-self: center;
    color: white;
    width: 60%;
    text-align: justify;

    @media only screen and (min-width: 1200px) {
      padding-left: calc((100vw - 1200px) / 2);
    }
    @media only screen and (max-width: 500px) {
      font-size: 0.625rem;
      margin: 24px;
    }
  }
`;

const ImageTrailing = styled.img.attrs({
  src: "https://maismagazine.pt/wp-content/uploads/2023/06/r1-2.jpg",
})`
  width: 40%;
  object-fit: cover;
`;
const SectionTwoAboutUs = () => {
  const { data } = useLandPageContext();

  return (
    <Content>
      <div
        dangerouslySetInnerHTML={{
          __html:
            data.generalInfo.aboutUs2[LocalizedLanguage.currentLanguage()],
        }}
      ></div>
      <ImageTrailing />
    </Content>
  );
};

export default SectionTwoAboutUs;
