import React from "react";

interface EditComponentProps {
  onClick: () => void;
}

function ButtonEdit({ onClick }: EditComponentProps) {
  return (
    <svg
      onClick={() => onClick()}
      className="cursor-pointer"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_143_756)">
        <path
          d="M3 17.25V21H6.75L17.81 9.94L14.06 6.19L3 17.25ZM20.71 7.04C21.1 6.65 21.1 6.02 20.71 5.63L18.37 3.29C17.98 2.9 17.35 2.9 16.96 3.29L15.13 5.12L18.88 8.87L20.71 7.04Z"
          fill="#13EC00"
        />
      </g>
      <defs>
        <clipPath id="clip0_143_756">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

interface ButtonDeleteProps {
  onClick: () => void;
}

function ButtonDelete({ onClick }: ButtonDeleteProps) {
  return (
    <svg
      onClick={onClick}
      className="cursor-pointer transition-colors hover:drop-shadow"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_143_762)">
        <path
          d="M6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7H6V19ZM8.46 11.88L9.87 10.47L12 12.59L14.12 10.47L15.53 11.88L13.41 14L15.53 16.12L14.12 17.53L12 15.41L9.88 17.53L8.47 16.12L10.59 14L8.46 11.88ZM15.5 4L14.5 3H9.5L8.5 4H5V6H19V4H15.5Z"
          fill="#FF0A0A"
        />
      </g>
      <defs>
        <clipPath id="clip0_143_762">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export { ButtonEdit, ButtonDelete };
