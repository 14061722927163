import React, { useEffect, useRef, useState } from "react";
import { NlTextField } from "../../../../../../../commom/shared_components/NlTextField";
import { NlPrimaryButton } from "../../../../../../../commom/shared_components/NlPrimaryButton";
import { useFieldArray, useForm } from "react-hook-form";
import { ButtonDelete } from "../../partners/components/BtnEdit";
import {
  collection,
  doc,
  getDoc,
  getFirestore,
  setDoc,
} from "@firebase/firestore";
import { NlLoading } from "../../../../../../../commom/shared_components/NlLoading";
import { GeneralInfoDTO } from "../../../../../dtos/GeneralInfoDTO";
import { instanceToPlain } from "class-transformer";
import { NLTextLanguageField } from "../../../../../../../commom/shared_components/NlTextLanguageField";

export function GeneralInfo() {
  const db = getFirestore();

  const [isLoading, setIsLoading] = useState(false);

  const { control, register, handleSubmit, setValue } =
    useForm<GeneralInfoDTO>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "macroPoints",
  });

  const [listCustomBanners, setListCustomBanners] = useState<Array<string>>([]);
  const newBannerRef = useRef<HTMLInputElement>();

  useEffect(() => {
    getGeneralInfos();
  }, []);

  async function getGeneralInfos() {
    setIsLoading(true);
    const generalInfos = (
      await getDoc(doc(collection(db, "general-info"), "main"))
    ).data() as GeneralInfoDTO | undefined;

    setIsLoading(false);

    if (!generalInfos) return;
    setValue("aboutUs1.en", generalInfos.aboutUs1.en);
    setValue("aboutUs1.es", generalInfos.aboutUs1.es);
    setValue("aboutUs1.pt", generalInfos.aboutUs1.pt);

    setValue("aboutUs2.en", generalInfos.aboutUs2.en);
    setValue("aboutUs2.es", generalInfos.aboutUs2.es);
    setValue("aboutUs2.pt", generalInfos.aboutUs2.pt);

    setValue("contactUs.en", generalInfos.contactUs.en);
    setValue("contactUs.es", generalInfos.contactUs.es);
    setValue("contactUs.pt", generalInfos.contactUs.pt);

    setValue("agreements.en", generalInfos.agreements.en);
    setValue("agreements.es", generalInfos.agreements.es);
    setValue("agreements.pt", generalInfos.agreements.pt);

    setValue("urlImageRightBanner", generalInfos.urlImageRightBanner);
    setValue("supportEmail", generalInfos.supportEmail);
    setValue("macroPoints", generalInfos.macroPoints);

    setListCustomBanners(generalInfos.listCustomBanners);
  }

  async function updateGeneralInfo(input: GeneralInfoDTO) {
    setIsLoading(true);
    try {
      input.listCustomBanners = listCustomBanners;
      await setDoc(
        doc(collection(db, "general-info"), "main"),
        instanceToPlain(input)
      );
    } catch (_) {
    } finally {
      setIsLoading(false);
    }
  }

  function addImageInList(newURL: string) {
    setListCustomBanners([...listCustomBanners, newURL]);
    newBannerRef.current!.value = "";
  }

  function deleteURL(url: string) {
    const newURLList: Array<string> = listCustomBanners.filter((banner) => {
      return banner !== url;
    });

    setListCustomBanners(newURLList);
  }

  return isLoading ? (
    <NlLoading />
  ) : (
    <div className="h-screen overflow-scroll overflow-x-hidden p-12">
      <form onSubmit={handleSubmit(updateGeneralInfo)} className="mb-2 gap-5">
        <h1 className="my-3 text-2xl">
          <strong>Banners</strong>
        </h1>
        <div className="flex gap-5 flex-grow">
          <div className="grow">
            <NlTextField
              id="instName"
              label="Insira a url do banner customizado"
              ref={newBannerRef}
            />
          </div>
          <NlPrimaryButton
            label="Adicionar"
            loading={false}
            onClick={() => {
              if (!newBannerRef.current?.value) return;
              addImageInList(newBannerRef.current?.value);
            }}
          />
        </div>
        {listCustomBanners.map((link) => {
          return (
            <div className="w-full flex justify-between p-2 align-middle border-solid border-2 border-nl-blue-100 rounded my-1">
              <a className="">{link}</a>{" "}
              <ButtonDelete onClick={() => deleteURL(link)} />
            </div>
          );
        })}
        <h1 className="my-3 text-2xl">
          <strong>Sobre Nós, Seção 1</strong>
        </h1>
        <NLTextLanguageField
          mapRegister={{
            pt: register("aboutUs1.pt", { required: true }),
            en: register("aboutUs1.en", { required: true }),
            es: register("aboutUs1.es", { required: true }),
          }}
        />
        <h1 className="my-3 text-2xl">
          <strong>Sobre Nós, Seção 2</strong>
        </h1>
        <div>
          <NLTextLanguageField
            mapRegister={{
              pt: register("aboutUs2.pt", { required: true }),
              en: register("aboutUs2.en", { required: true }),
              es: register("aboutUs2.es", { required: true }),
            }}
          />
        </div>
        <h1 className="my-3 text-2xl">
          <strong>Macro Pontos</strong>
        </h1>
        <div className="flex flex-row gap-8">
          {fields.map((field, index) => (
            <div className="relative flex flex-col gap-3" key={field.id}>
              <div className="absolute top-0 right-0">
                <ButtonDelete onClick={() => remove(index)} />
              </div>
              <NlTextField
                id={`${index}`}
                label="Imagem"
                register={register(`macroPoints.${index}.img`, {
                  required: true,
                })}
              />
              <NLTextLanguageField
                placeholder="Titulo "
                mapRegister={{
                  pt: register(`macroPoints.${index}.title.pt`, {
                    required: true,
                  }),
                  en: register(`macroPoints.${index}.title.en`, {
                    required: true,
                  }),
                  es: register(`macroPoints.${index}.title.es`, {
                    required: true,
                  }),
                }}
              />
              <NLTextLanguageField
                placeholder="Descrição "
                mapRegister={{
                  pt: register(`macroPoints.${index}.desc.pt`, {
                    required: true,
                  }),
                  en: register(`macroPoints.${index}.desc.en`, {
                    required: true,
                  }),
                  es: register(`macroPoints.${index}.desc.es`, {
                    required: true,
                  }),
                }}
              />
            </div>
          ))}
        </div>
        <NlPrimaryButton
          label="Adicionar Macro ponto"
          onClick={() => {
            append({
              desc: {
                en: "",
                es: "",
                pt: "",
              },
              img: "",
              title: {
                en: "",
                es: "",
                pt: "",
              },
            });
          }}
        />

        <div className="mt-4 grow">
          <NlTextField
            id="urlRightImage"
            label="Url imagem do lado direito"
            register={register("urlImageRightBanner", { required: true })}
          />
        </div>
        <h1 className="my-3 text-2xl">
          <strong>Convênios</strong>
        </h1>
        <NLTextLanguageField
          mapRegister={{
            pt: register("agreements.pt", { required: true }),
            en: register("agreements.en", { required: true }),
            es: register("agreements.es", { required: true }),
          }}
        />
        <h1 className="my-3 text-2xl">
          <strong>Fale Conosco</strong>
        </h1>
        <div>
          <NLTextLanguageField
            mapRegister={{
              pt: register("contactUs.pt", { required: true }),
              en: register("contactUs.en", { required: true }),
              es: register("contactUs.es", { required: true }),
            }}
          />
        </div>
        <div className="mt-4 grow">
          <NlTextField
            id="supportEmail"
            label="Email de suporte"
            register={register("supportEmail", { required: true })}
          />
        </div>
        <div className="w-full">
          <NlPrimaryButton label="Salvar atualizações" onClick={() => {}} />
        </div>
      </form>
    </div>
  );
}
