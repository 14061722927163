import React, { useEffect, useRef, useState } from "react";
import {
  AdminEditalForm,
  AdminEditalFormRef,
} from "./components/AdminEditalForm";
import { PartnersDTO } from "../../../../dtos/PartnersDTO";
import {
  addDoc,
  collection,
  getDocs,
  getFirestore,
  onSnapshot,
  deleteDoc,
  doc,
  updateDoc,
} from "@firebase/firestore";
import { NlLoading } from "../../../../../../commom/shared_components/NlLoading";
import { EditalDTO } from "../../../../dtos/EditalDTO";
import { instanceToPlain } from "class-transformer";
import { ButtonDelete, ButtonEdit } from "../partners/components/BtnEdit";
import { LocalizedLanguage } from "../../../../data/models/LocalizedLanguageModel";

export const AdminEditals = () => {
  const db = getFirestore();
  const formRef = useRef<AdminEditalFormRef>(null);

  const [partners, setPartners] = useState<PartnersDTO[]>([]);
  const [editals, setEditals] = useState<EditalDTO[]>([]);
  const [edit, setEdit] = useState<EditalDTO>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadEditals();
  }, []);

  useEffect(() => {
    const editalRef = collection(db, "editals");

    onSnapshot(editalRef, (snapshot) => {
      const newEditalList: EditalDTO[] = snapshot.docs.map((doc) => {
        return {
          id: doc.id,
          ...doc.data(),
        } as EditalDTO;
      });
      setEditals(newEditalList);
    });
  }, []);

  const loadEditals = async () => {
    setLoading(true);
    const db = getFirestore();
    const partnerRef = collection(db, "partners");
    const editalRef = collection(db, "editals");

    const [partnerResult, editalResult] = await Promise.all([
      getDocs(partnerRef),
      getDocs(editalRef),
    ]);

    setPartners(
      partnerResult.docs.map((doc) => {
        return {
          id: doc.id,
          ...doc.data(),
        } as PartnersDTO;
      })
    );

    setEditals(
      editalResult.docs.map((doc) => {
        return {
          id: doc.id,
          ...doc.data(),
        } as EditalDTO;
      })
    );
    setLoading(false);
  };

  const updateEdital = async (data: EditalDTO) => {
    await updateDoc(doc(db, "editals", edit!.id), instanceToPlain(data));
    setEdit(undefined);
  };

  const addNewEdital = async (data: EditalDTO) => {
    const editalRef = collection(db, "editals");
    await addDoc(editalRef, instanceToPlain(data));
    formRef.current!.reset();
  };

  const deleteEdital = async (data: EditalDTO) => {
    await deleteDoc(doc(db, "editals", data.id));
  };

  if (loading) {
    return <NlLoading />;
  }
  if (edit) {
    return (
      <div
        onClick={() => {
          setEdit(undefined);
        }}
        className="fixed flex flex-col justify-center items-center bg-black/10 w-screen h-screen top-0 left-0 cursor-pointer"
      >
        <div
          onClick={(e) => e.stopPropagation()}
          className="absolute flex items-start p-10 bg-white border-2 w-[80%] h-[80%] shadow-2xl rounded overflow-y-auto"
        >
          <AdminEditalForm
            key={"edit"}
            onSubmit={updateEdital}
            partners={partners}
            edital={edit}
            submitLabel="Salvar edições"
          />
        </div>
      </div>
    );
  }
  return (
    <div className="relative w-full h-full p-8">
      <strong className="h-12 text-2xl">Cadastrar Edital</strong>
      <div className="h-4" />
      <AdminEditalForm
        key={"create"}
        ref={formRef}
        partners={partners}
        onSubmit={addNewEdital}
      />
      <div className="my-10  h-1 bg-nl-blue-150" />
      <table className="w-full table-fixed ">
        <thead>
          <tr className="[&>th]:p-2 [&>th]:text-md bg-nl-blue-100  text-white text-start">
            <th className="text-start" colSpan={2}>
              Titulo
            </th>
            <th className="text-start" colSpan={2}>
              Duração
            </th>
            <th className="text-start" colSpan={2}>
              Modelo
            </th>
            <th className="text-start" colSpan={2}>
              Parceiro
            </th>
            <th className="text-start" colSpan={2}>
              Url do edital
            </th>
            <th className="text-start" colSpan={1}>
              Ativo?
            </th>
            <th className="text-start" colSpan={1}></th>
          </tr>
        </thead>
        <tbody>
          {editals?.map((edital) => {
            return (
              <tr
                className="[&>td]:p-2  text-xs font-medium [&>td]:border"
                key={edital.id}
              >
                <td colSpan={2}>
                  <div className="line-clamp-2">
                    {edital.title[LocalizedLanguage.currentLanguage()]}
                    {` (${LocalizedLanguage.currentLanguage()})`}
                  </div>
                </td>
                <td colSpan={2}>
                  <div className="line-clamp-2">
                    {edital.duration[LocalizedLanguage.currentLanguage()]}
                    {` (${LocalizedLanguage.currentLanguage()})`}
                  </div>
                </td>
                <td colSpan={2}>
                  <div className="line-clamp-2">
                    {edital.model[LocalizedLanguage.currentLanguage()]}
                    {` (${LocalizedLanguage.currentLanguage()})`}
                  </div>
                </td>
                <td colSpan={2}>
                  <div className="line-clamp-2">
                    {partners.find((p) => p.id == edital.partnerId)?.name[
                      LocalizedLanguage.currentLanguage()
                    ] ??
                      edital.otherUniversity?.[
                        LocalizedLanguage.currentLanguage()
                      ] ??
                      "Não encontrado"}
                    {` (${LocalizedLanguage.currentLanguage()})`}
                  </div>
                </td>
                <td colSpan={2}>
                  <a
                    className="line-clamp-2 hover:underline hover:text-blue-700"
                    title={edital.urlEdital}
                    href={edital.urlEdital}
                    target="blank"
                  >
                    {edital.urlEdital}
                  </a>
                </td>
                <td colSpan={1}>
                  <div className="line-clamp-2">
                    {edital.isActive ? "Sim" : "Não"}
                  </div>
                </td>

                <td colSpan={1} className="p-0">
                  <div className="flex flex-row gap-3 w-full justify-center">
                    <ButtonEdit onClick={() => setEdit(edital)} />
                    <ButtonDelete onClick={() => deleteEdital(edital)} />
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <div className="h-16"></div>
    </div>
  );
};
