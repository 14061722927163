import { createContext, useContext } from "react";
import { LandPageModel } from "./data/LandPageRepository";
export type LandPageContent = {
  data: LandPageModel;
};

export const LandPageContext = createContext<LandPageContent>({
  data: {} as LandPageModel,
});

export const useLandPageContext = () => useContext(LandPageContext);
