import { createBrowserRouter } from "react-router-dom";
import LandPage from "./land-page/LandPage";
import React from "react";
import AdminRoute from "./land-page/submodules/admin/AdminRoutes";

const AppRouter = createBrowserRouter([
  {
    path: "/",
    element: <LandPage />,
  },
  AdminRoute,
]);

export default AppRouter;
