import React from "react";
import { logoHorizontal } from "../../../../../../../commom/images/images";
import { IconGeneralInfo } from "../../../../../../../commom/icons/IconGeneralInfo";
import { IconPartners } from "../../../../../../../commom/icons/IconPartners";
import { useLocation, useNavigate } from "react-router-dom";
import { NlPrimaryButton } from "../../../../../../../commom/shared_components/NlPrimaryButton";
import { getAuth } from "firebase/auth";
import { IconEdital } from "../../../../../../../commom/icons/IconEdital";
interface MenuBarOptionProps {
  icon: React.ReactNode;
  text: string;
  onClick: () => void;
  isSelected: boolean;
}

const MenuBarOption = ({
  icon,
  text,
  isSelected,
  onClick,
}: MenuBarOptionProps) => {
  return (
    <div
      onClick={onClick}
      className={
        "flex flex-row items-center h-14 gap-3 w-full py-3 px-6 transition hover:bg-nl-blue-150 cursor-pointer " +
        (isSelected ? "bg-nl-blue-150" : "")
      }
    >
      {icon}
      <p className="font-bold text-white text-xl">{text}</p>
    </div>
  );
};

export const HomeNavBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <div className="flex flex-col h-full w-80 bg-nl-blue-100 items-stretch pb-6 ">
      <img src={logoHorizontal} className="w-3/5 my-10  self-center text-xl" />
      <MenuBarOption
        icon={<IconGeneralInfo />}
        text="General info"
        isSelected={location.pathname.includes("general-info")}
        onClick={() => navigate("/admin/home/general-info")}
      />
      <div className="w-full h-0.5 bg-white/20" />
      <MenuBarOption
        icon={<IconPartners />}
        text="Parceiros"
        isSelected={location.pathname.includes("partners")}
        onClick={() => navigate("/admin/home/partners")}
      />
      <div className="w-full h-0.5 bg-white/20" />
      <MenuBarOption
        icon={<IconEdital />}
        text="Editais"
        isSelected={location.pathname.includes("editals")}
        onClick={() => navigate("/admin/home/editals")}
      />
      <div className="w-full h-0.5 bg-white/20" />
      <div className="h-full"></div>
      <NlPrimaryButton
        className="mx-6"
        label="Deslogar"
        onClick={() => getAuth().signOut()}
      />
    </div>
  );
};
