import React, { useEffect, useState } from "react";
import styled from "styled-components";

import AppColors from "../../../../../commom/AppColors";
import "../../../../../index.css";
import { useTranslation } from "react-i18next";
import {
  LandPageSections,
  moveSection,
  moveToTop,
} from "../../../utils/moveSection";
import Drawer from "./Drawer";
import { MenuLanguageOption } from "./MenuLanguageOption";

const logoHorizontal = require("../../../../../assets/images/logo-horizontal.png");
const iconMenu = require("../../../../../assets/images/icon-menu.png");

interface IMenuOptionProps {
  $isActive?: boolean;
}
const MenuBarStyle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  width: 100%;
  overflow: hidden;
  z-index: 2;
  padding-left: 24px;
  font-weight: bold;
  font-size: 1.5rem;
  color: white;
`;

const LogoNiltonLins = styled.img.attrs({
  src: logoHorizontal,
})`
  max-width: 250px;
  opacity: 1;

  @media only screen and (max-width: 500px) {
    max-height: 40px;
    max-width: 80px;
  }
`;

const MenuOptionsWrapper = styled.div`
  position: fixed;
  display: flex;
  z-index: 1000;
  width: 100%;
  justify-content: center;
`;

const MenuOptions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  gap: 24px;
  max-width: 1200px;
`;
interface MenuOptionsBackgroundLayerProps {
  $needBackground: boolean;
}
const MenuOptionsBackgroundLayer = styled.div<MenuOptionsBackgroundLayerProps>`
  position: absolute;
  background-color: ${AppColors.blue100};
  height: 100%;
  width: 100%;
  z-index: -1;
  box-shadow: 0px -5px 10px ${AppColors.blue100};
  filter: blur(3px);
  visibility: ${(props) => (props.$needBackground ? "visible" : "hidden")};
  opacity: ${(props) => (props.$needBackground ? 0.8 : 0)};
  transition:
    visibility 1s,
    opacity 0.5s linear;
`;

const MenuOption = styled.a<IMenuOptionProps>`
  color: white;
  width: 100%;
  text-decoration: none;
  text-align: center;
  font-weight: ${(props) => (props.$isActive ? "bold" : 300)};
  font-size: ${(props) => (props.$isActive ? "1.5rem" : "1.4rem")};
  font-family: ${(props) => (props.$isActive ? "FlamSemiBold" : "FlamaBook")};
  &:hover {
    cursor: pointer;
    color: whitesmoke;
  }
`;

const Title = styled.div`
  margin-top: 120px;
  font-size: 4rem;
  font-family: "FlamSemiBold";
  color: white;

  @media only screen and (max-width: 500px) {
    font-size: 1rem;
    margin-top: 18px;
  }
`;

const Subtitle = styled.div`
  font-size: 2.25rem;
  font-family: "FlamaBook";
  font-weight: 400;
  color: white;
  margin-top: 12px;
  white-space: "pre-line";
  @media only screen and (max-width: 500px) {
    font-size: 0.75rem;
    margin-top: 8px;
  }
`;

const InscriptionButton = styled.button`
  font-size: 2rem;
  font-family: "FlamSemiBold";
  background-color: ${AppColors.red100};
  font-weight: 600;
  margin-top: 50px;
  color: white;
  border-radius: 12px;
  margin-bottom: 24px;
  padding: 16px;
  padding-inline: 24px;
  border: none;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  &:hover {
    cursor: pointer;
    background-color: ${AppColors.red150};
  }
  @media only screen and (max-width: 500px) {
    margin-top: 18px;
    border-radius: 6px;
    font-size: 0.5rem;
    padding: 8px;
    padding-inline: 12px;
  }
`;

const TitleContent = styled.div`
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: start;
`;

const TrailingInfo = styled.div`
  font-size: 1rem;
  font-family: "FlamaBook";
  font-weight: bold;
  text-align: end;
  bottom: 24px;

  overflow-wrap: break-word;
  margin-bottom: 32px;

  span {
    font-family: "FlamSemiBold";
    font-weight: bold;
  }

  @media only screen and (max-width: 500px) {
    font-size: 0.5rem;
    padding: 8px;
    text-align: end;
    padding-inline: 12px;
    margin-bottom: 8px;
    margin-right: 24px;
  }
`;

const MenuHeaderMobile = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  display: fixed;
`;

const MenuHamburguerOption = styled.img.attrs({
  src: iconMenu,
})`
  height: 14px;
  top: 24px;
  right: 24px;
  z-index: 2;
`;

interface IMenuBarProps {
  currentPartnerName: string | undefined;
}

export default function MenuBar({ currentPartnerName }: IMenuBarProps) {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 500);

  const { t } = useTranslation();
  const [needBackground, setNeedBackground] = useState(false);
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);

  const listenScrollEvent = () => {
    if (window.scrollY < 20) {
      return setNeedBackground(false);
    }
    return setNeedBackground(true);
  };
  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, []);

  useEffect(() => {
    setIsMobile(window.innerWidth < 500);
    window.addEventListener("resize", () => {
      if (window.innerWidth < 500) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    });
  }, []);

  return (
    <div
      style={{
        width: "100%",
        position: "relative",
      }}
    >
      {!isMobile && (
        <MenuOptionsWrapper id="menu-desktop">
          <MenuOptionsBackgroundLayer
            $needBackground={needBackground}
          ></MenuOptionsBackgroundLayer>
          <MenuOptions>
            <LogoNiltonLins onClick={moveToTop} />
            <MenuOption
              $isActive={true}
              onClick={() => moveSection(LandPageSections.aboutUs)}
            >
              {t("aboutTheProject")}
            </MenuOption>
            <MenuOption
              $isActive={true}
              onClick={() => moveSection(LandPageSections.editals)}
            >
              {t("inscriptions")}
            </MenuOption>
            <MenuOption
              $isActive={true}
              onClick={() => moveSection(LandPageSections.partners)}
            >
              {t("agreements")}
            </MenuOption>
            <MenuOption
              $isActive={true}
              onClick={() => moveSection(LandPageSections.contactUs)}
            >
              {t("contactUs")}
            </MenuOption>
            <MenuLanguageOption />
          </MenuOptions>
        </MenuOptionsWrapper>
      )}

      <MenuBarStyle>
        <TitleContent>
          {isMobile && (
            <MenuHeaderMobile>
              <LogoNiltonLins />
              <div
                style={{
                  position: "fixed",
                  height: "40px",
                  width: "40px",
                  top: "12px",
                  right: "12px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "10px",
                  overflow: "hidden",
                }}
              >
                <div
                  style={{
                    backgroundColor: needBackground
                      ? AppColors.blue150
                      : "transparent",
                    transition: "background-color 1s",
                    filter: "blur(3px)",
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    zIndex: 1,
                  }}
                ></div>{" "}
                <MenuHamburguerOption
                  onClick={() => setIsOpenDrawer(!isOpenDrawer)}
                />
              </div>
            </MenuHeaderMobile>
          )}

          <Title>
            Nilton Lins <br /> {t("international")}
          </Title>
          <Subtitle style={{ whiteSpace: "pre-line" }}>
            {t("headerDescription")}
          </Subtitle>
          <div>
            <InscriptionButton
              onClick={() => moveSection(LandPageSections.editals)}
            >
              {t("incriptionOpen")}
            </InscriptionButton>
          </div>
          {currentPartnerName &&
            (isMobile ? (
              <TrailingInfo>
                <span>{currentPartnerName}</span> <br />
                {t("niltonLinsPartner")}
              </TrailingInfo>
            ) : (
              <TrailingInfo>
                <span>{currentPartnerName}</span> | {t("niltonLinsPartner")}
              </TrailingInfo>
            ))}
        </TitleContent>
      </MenuBarStyle>
      {isMobile && (
        <Drawer
          isOpen={isOpenDrawer}
          onBackgroundTapped={() => setIsOpenDrawer(false)}
        ></Drawer>
      )}
    </div>
  );
}
