export enum LandPageSections {
  editals = "editals",
  aboutUs = "about-us",
  partners = "partners",
  contactUs = "contact-us",
}

export const moveSection = (section: LandPageSections) => {
  const sectionNode = document.getElementById(section.valueOf());
  const menuDesktopNode = document.getElementById("menu-desktop");
  const offsetMenuDesktop =
    menuDesktopNode?.getBoundingClientRect().height ?? 0;
  const offsetToSection = sectionNode?.getBoundingClientRect().top ?? 0;
  window.scrollTo({
    top: offsetToSection - offsetMenuDesktop + window.scrollY,
    behavior: "smooth",
  });
};

export const moveToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};
