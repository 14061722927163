import styled from "styled-components";
import MacroPoint from "./MacroPoint";
import { useLandPageContext } from "../../../LandPageContext";
import React from "react";
import { LocalizedLanguage } from "../../../data/models/LocalizedLanguageModel";
const Content = styled.div`
  display: flex;
  flex-direction: row;
  background-color: white;
  width: 100%;
  padding-block: 40px;
  justify-content: center;
  gap: 40px;
  text-align: center;

  @media only screen and (max-width: 500px) {
    padding-block: 24px;
  }
`;

const SectionThreeAboutUs = () => {
  const { data } = useLandPageContext();
  console.log(data);
  if (data.generalInfo.macroPoints === undefined) return <></>;
  return (
    <Content>
      {data.generalInfo.macroPoints?.map(
        ({ title, desc, img }, index) => (
          <MacroPoint
            key={index}
            title={title[LocalizedLanguage.currentLanguage()]}
            description={desc[LocalizedLanguage.currentLanguage()]}
            img={img}
          />
        )
      )}
    </Content>
  );
};

export default SectionThreeAboutUs;
