import styled from "styled-components";
import SectionOneAboutUs from "./components/SectionOneAboutUs";
import SectionTwoAboutUs from "./components/SectionTwoAboutUs";
import SectionThreeAboutUs from "./components/SectionThreeAboutUs";
import React from "react";

const AboutUsBody = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LandPageAboutUs = () => {
  return (
    <AboutUsBody id="about-us">
      <SectionOneAboutUs />
      <SectionTwoAboutUs />
      <SectionThreeAboutUs />
    </AboutUsBody>
  );
};

export default LandPageAboutUs;
