import { useEffect, useState } from "react";
import { LandPageModel, LandPageRepository } from "./data/LandPageRepository";

export function useLandPageData(): LandPageModel {
  const [landPageData, setLandPageData] = useState<LandPageModel>();

  useEffect(() => {
    const landPageRepository = new LandPageRepository();
    landPageRepository.fetchAllSections().then((data) => {
      setLandPageData(data);
    });
  }, []);

  return landPageData!;
}
