import { getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";

export const useAuthGuard = () => {
  const navigate = useNavigate();
  return {
    resolveAuth: () =>
      getAuth().onAuthStateChanged((user) => {
        if (!user) {
          navigate("/admin/login");
        }
      }),
  };
};
