import React, { forwardRef } from "react";
interface NlTextFieldProps {
  id: string;
  label: string;
  register?: any;
}

export const NlTextField = forwardRef(
  ({ label, id, register }: NlTextFieldProps, forwardedRef) => {
    return (
      <div>
        <label
          htmlFor={id}
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          {label}
        </label>
        <div className="relative mt-2 rounded-md shadow-sm">
          <input
            ref={forwardedRef}
            id={id}
            name={id}
            {...register}
            type="text"
            className="block w-full
            rounded-md border-0 py-1.5 p-4
          text-gray-900
          ring-1 ring-inset 
          ring-nl-blue-150
          focus:outline-none
          focus:ring-2
         focus:ring-nl-blue-150
           sm:text-sm sm:leading-6 transition"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
              }
            }}
          />
        </div>
      </div>
    );
  }
);
