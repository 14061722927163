import React from "react";
import styled from "styled-components";
import AppColors from "../../../../../commom/AppColors";
import { useTranslation } from "react-i18next";
import { LandPageSections, moveSection } from "../../../utils/moveSection";
import { MenuLanguageOption } from "./MenuLanguageOption";

const Background = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: ${AppColors.blue100};
  z-index: 101;
  top: 0px;
  opacity: 0.3;
`;

const BackgroundOptions = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  right: 0;
  height: 100vh;
  width: 160px;
  background-color: ${AppColors.blue100};
  box-shadow: 0px 0px 3px ${AppColors.blue100};
  padding-top: 10%;
  padding-inline: 24px;
  z-index: 102;
`;

const Option = styled.div`
  color: white;
  font-size: 0.75rem;
  margin-top: 16px;
  margin-bottom: 16px;
  font-family: "FlamaBook";
`;

const Divider = styled.div`
  width: 100%;
  height: 0.2px;
  background-color: grey;
`;

export default function Drawer(props: any) {
  const { t } = useTranslation();

  return (
    <div
      style={{
        display: props.isOpen ? "block" : "none",
        animationDirection: "alternate",
        zIndex: 100,
      }}
    >
      <Background
        onClick={() => {
          props.onBackgroundTapped();
        }}
      />

      <BackgroundOptions>
        <Divider />
        <Option onClick={() => onOptionTapped(LandPageSections.aboutUs, props)}>
          {t("aboutTheProject")}
        </Option>
        <Divider />
        <Option onClick={() => onOptionTapped(LandPageSections.editals, props)}>
          {t("inscriptions")}
        </Option>
        <Divider />
        <Option
          onClick={() => onOptionTapped(LandPageSections.partners, props)}
        >
          {t("agreements")}
        </Option>
        <Divider />
        <Option
          onClick={() => onOptionTapped(LandPageSections.contactUs, props)}
        >
          {t("contactUs")}
        </Option>
        <Divider />
        <Option>
          <MenuLanguageOption />
        </Option>
      </BackgroundOptions>
    </div>
  );
}

function onOptionTapped(section: LandPageSections, props: any) {
  props.onBackgroundTapped();
  moveSection(section);
}
